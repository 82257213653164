@font-face {
  font-family: 'Museo Sans';
  src: url('../public/fonts/MuseoSans_500.otf');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 700;
  src: url('../public/fonts/MuseoSans_700.otf');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 900;
  src: url('../public/fonts/MuseoSans_900.otf');
}

@font-face {
  font-family: 'Crimson Text';
  font-weight: 600;
  src: url('../public/fonts/CrimsonText-SemiBold.ttf');
}
